import React, { useEffect } from 'react';
import BlogCard from '../components/BlogCard';
import blogData from '../data/blogData.json';

const BlogScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Ordenar los artículos por ID de mayor a menor
  const sortedBlogData = [...blogData].sort((a, b) => b.id - a.id);

  return (
    <div className="blog-container">
      {/* Encabezado del Blog */}
      <header className="blog-header">
        <h1 className="blog-title">"Perspectiva Fiscal"</h1>
        <p className="blog-subtitle">Información clave sobre contabilidad, impuestos y estrategias financieras.</p>
      </header>

      {/* Sección de Posts */}
      <section className="blog-content">
        {sortedBlogData.map((post, index) => (
          <BlogCard key={index} post={post} />
        ))}
      </section>
    </div>
  );
};

export default BlogScreen;
