import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import MainScreen from './screens/MainScreen';
import AboutScreen from './screens/AboutScreen';
import BlogScreen from './screens/BlogScreen';
import ArticleDetail from './screens/BlogArticleScreen';
import ErrorPage from './screens/ErrorScreen';
import ServicesScreen from './screens/ServicesScreen';


function App() {
  return (
    <BrowserRouter>
      <Header></Header>
      <Routes className="app">
        <Route path='/' element={<MainScreen />} />
        <Route path='/about' element={<AboutScreen />} />
        <Route path='/servicios' element={<ServicesScreen />} />
        <Route path='/blog' element={<BlogScreen />} />
        <Route path="/article/:id" element={<ArticleDetail />} />
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
  )


}

const Header = () => {
  const logoUrl = `${process.env.PUBLIC_URL}/media/solcont.png`;
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleMenu = () => setMenuOpen((prev) => !prev);
  const closeMenu = () => setMenuOpen(false);

  return (
    <header className={`header ${scrolled ? "header-scroll" : ""}`}>
      <div className="container">
        {/* Logo */}
        <div className="logo-container">
          <Link to="/">
            <img className="logo" src={logoUrl} alt="SolCont | Contabilidad & Impuestos" />
          </Link>
        </div>

        {/* Menú de navegación */}
        <nav className={`nav-menu ${isMenuOpen ? "menu-open" : ""}`}>
          <ul>
            <li><Link to="/" onClick={closeMenu}>Inicio</Link></li>
            <li><Link to="/about" onClick={closeMenu}>¿Quiénes Somos?</Link></li>
            <li><Link to="/servicios" onClick={closeMenu}>Servicios</Link></li>
            <li><Link to="/blog" onClick={closeMenu}>Blog</Link></li>
            <li>
              <a href="tel:2381888842" className="contact-link" onClick={closeMenu}>
                <i className="fa-solid fa-phone icon"></i> 238-188-8842
              </a>
            </li>
            <li>
              <a href="https://wa.me/522381888842" target="_blank" rel="noopener noreferrer" className="whatsapp-link" onClick={closeMenu}>
                <i className="fa-brands fa-whatsapp icon"></i> WhatsApp
              </a>
            </li>
          </ul>
        </nav>

        {/* Botón de menú hamburguesa */}
        <button id="menu" className={`button-menu ${isMenuOpen ? "active" : ""}`} onClick={toggleMenu}>
          <i className={`fas ${isMenuOpen ? "fa-times" : "fa-bars"} hamburger-menu`}></i>
        </button>

        {/* Overlay para cerrar el menú en móviles */}
        {isMenuOpen && <div className="overlay" onClick={closeMenu}></div>}
      </div>
    </header>
  );
};


const Footer = () => {
  const contaxLogo = `${process.env.PUBLIC_URL}/media/solcontlogowhite.png`;
  const isaSoftLogo = `${process.env.PUBLIC_URL}/media/isasoft.png`;

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo y descripción */}
        <div className="footer-brand">
          <img className="footer-logo" src={contaxLogo} alt="SolCont | Despacho Contable" />
          <p className="footer-description">
            SolCont brinda asesoría contable y fiscal para optimizar tu negocio y cumplir con todas tus obligaciones fiscales de manera efectiva.
          </p>
        </div>

        {/* Enlaces rápidos */}
        <div className="footer-links-container">
          <h3 className="footer-title">Enlaces rápidos</h3>
          <ul className="footer-links">
            <li>
              <Link to="/about">Nosotros</Link>
            </li>
            <li>
              <Link to="/plans">Planes</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>

        {/* Redes Sociales */}
        <div className="footer-social">
          <h3 className="footer-title">Síguenos en redes</h3>
          <ul className="social-icons">
            <li>
              <a href="https://www.instagram.com/solcont_mx" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/SolucionesContablesTh" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://wa.me/522382225136?text=Quiero%20m%C3%A1s%20informaci%C3%B3n" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp"></i>
              </a>
            </li>
            <li>
              <a href="mailto:elizabeth.zavaleta@solcont.com.mx" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-envelope"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Derechos y desarrollador */}
      <div className="footer-bottom">
        <small className="rights">&copy; {new Date().getFullYear()} SolCont. Todos los derechos reservados.</small>
        <a className="isasoft-container" href="https://www.isasoft.com.mx">
          <img className="isasoftlogo" src={isaSoftLogo} alt="isaSoft" />
        </a>
      </div>
    </footer>

  );
}




export default App;