import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import blogData from '../data/blogData.json';


const ArticleDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const selectedArticle = blogData.find(item => item.id.toString() === id);

    if (selectedArticle) {
      setArticle(selectedArticle);
      setLoading(false);
    } else {
      setError('Artículo no encontrado');
      setLoading(false);
    }
  }, [id]);

  if (loading) {
    return <div className="loading">Cargando...</div>;
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="article-container">
      <article className="article-content">
        <h1 className="article-title">{article.title}</h1>
        <img className="article-image" src={article.image} alt={article.title} />
        <h3 className="article-description">{article.description}</h3>
        <small className="article-date">{article.date}</small>

        <div className="article-body" dangerouslySetInnerHTML={{ __html: `
          ${article.body1 ? article.body1 + '<br/><br/>' : ''}
          ${article.body2 ? article.body2 + '<br/><br/>' : ''}
          ${article.body3 ? article.body3 + '<br/><br/>' : ''}
          ${article.body4 ? article.body4 + '<br/><br/>' : ''}
          ${article.body5 ? article.body5 + '<br/><br/>' : ''}
          ${article.body6 ? article.body6 + '<br/><br/>' : ''}
          ${article.body7 ? article.body7 + '<br/><br/>' : ''}
          ${article.body8 ? article.body8 + '<br/><br/>' : ''}
          ${article.body9 ? article.body9 + '<br/><br/>' : ''}
          ${article.body10 ? article.body10 + '<br/><br/>' : ''}
          ${article.body11 ? article.body11 + '<br/><br/>' : ''}
          ${article.body12 ? article.body12 + '<br/><br/>' : ''}
          ${article.body13 ? article.body13 + '<br/><br/>' : ''}
          ${article.body14 ? article.body14 + '<br/><br/>' : ''}
          ${article.body15 ? article.body15 : ''}
        ` }} />

        <small className="article-author">Autor: {article.autor}</small>
        <div className="article-footer">
          <Link className="back-button" to={"/blog"}>← Volver al Blog</Link>
        </div>
      </article>
    </div>
  );
};

export default ArticleDetail;
