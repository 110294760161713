import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TrustedCompanies.css";

const TrustedCompanies = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const companyLogos = [
    "company1.png", "company2.png", "company3.png", "company4.png", "company5.png"
  ];

  return (
    <section className="trusted-companies">
      <h2 className="trusted-title">Empresas que han confiado en nosotros</h2>
      <Slider {...settings}>
        {companyLogos.map((logo, index) => (
          <div key={index} className="company-slide">
            <img src={process.env.PUBLIC_URL + `/media/${logo}`} alt={`Empresa ${index + 1}`} className="company-logo" />
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default TrustedCompanies;