import React from "react";


const AboutScreen = () => {
  return (
    <section className="about-section">
      <div className="about-container">
        {/* Imagen de la contadora */}
        <div className="about-image">
          <img src={process.env.PUBLIC_URL + '/media/ElizabethZ.jpg'} alt="Lic. en Contaduría e Impuestos" />
        </div>
        
        {/* Información sobre la trayectoria */}
        <div className="about-content">
          <h2 className="about-title">Elizabeth Zavaleta | Lic. en Contaduría e Impuestos</h2>
          <p className="about-text">
            Con más de 10 años de experiencia, nuestra especialista en contaduría e impuestos es egresada del
            <strong> Instituto Tecnológico de Tehuacán en 2013</strong>. A lo largo de su carrera, ha demostrado 
            ser una profesional comprometida con la actualización constante, adaptándose a los cambios en las leyes fiscales 
            y brindando estrategias efectivas para optimizar la carga tributaria de sus clientes.
          </p>
          
          <h3 className="about-subtitle">Áreas de Especialización</h3>
          <ul className="about-list">
            <li><i className="fas fa-check"></i> Estrategias Fiscales Inteligentes</li>
            <li><i className="fas fa-check"></i> Optimización de Deducciones</li>
            <li><i className="fas fa-check"></i> Apelación y Defensa de Multas</li>
            <li><i className="fas fa-check"></i> Cumplimiento de Obligaciones Fiscales</li>
            <li><i className="fas fa-check"></i> Regularización de Situación Fiscal</li>
            <li><i className="fas fa-check"></i> Análisis y Planeación Financiera</li>
          </ul>
          
          <p className="about-text">
            Su enfoque se basa en una asesoría personalizada, asegurando que cada cliente tenga las mejores oportunidades
            para cumplir con sus obligaciones fiscales de manera eficiente y con el mayor beneficio posible.
          </p>
        </div>
      </div>

      {/* Segunda Sección - Compromiso y Ética */}
      <div className="about-second-section">
        {/* Texto */}
        <div className="about-second-content">
          <h3 className="about-subtitle">Nuestro Compromiso con la Excelencia</h3>
          <p className="about-second-text">
            En SolCont, estamos comprometidos en brindar un servicio de excelencia, basado en principios de ética profesional y buenas prácticas. 
            Cada recomendación y estrategia que ofrecemos está diseñada para garantizar el cumplimiento fiscal de nuestros clientes sin riesgos, 
            asegurando así tranquilidad y estabilidad financiera.
          </p>
        </div>
        
        {/* Imagen */}
        <div className="about-second-image">
          <img src={process.env.PUBLIC_URL + '/media/Elizabeth2.jpg'} alt="Compromiso y Ética" />
        </div>
      </div>
    </section>
  );
};

export default AboutScreen;
