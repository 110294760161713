import React from 'react';
import plansData from '../data/plansData.json';
import '../planCards.css';

const PlansSection = () => {
  return (
    <section className="plans-section">
      {plansData.map((plan, index) => (
        <PlanCard key={index} {...plan} />
      ))}
    </section>
  );
};

const PlanCard = ({ title, price, features, regime }) => {
  const whatsappLink = `https://wa.me/522381888842?text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20del%20plan%20${encodeURIComponent(title)}`;

  return (
    <div className="plan-card">
      <div className="plan-header">
        <h2 className='plan-title'>{title}</h2>
        <p className="plan-price">{price}</p>
      </div>
      <p className='plan-description'>{regime}</p>
      <ul className='plan-features'>
        {features.map((feature, index) => (
          <li key={index} className='plan-feature'>
            <i className="fas fa-check check-icon"></i> {feature}
          </li>
        ))}
      </ul>
      <div className='plan-btn-container'>
        <button className='plan-btn' onClick={() => window.open(whatsappLink, '_blank')}>
          Más Información
        </button>
      </div>
    </div>
  );
};

export default PlansSection;
