import React from "react";
import PlansSection from "../components/PlanSection";


const ServicesScreen = () => {
  const services = [
    {
      title: "Asesoría Contable y Fiscal",
      description: "Optimiza tus finanzas y cumple con tus obligaciones fiscales con asesoría experta.",
      icon: "fas fa-chart-line"
    },
    {
      title: "Estrategias de Deducción",
      description: "Maximiza tus deducciones legales para reducir tu carga tributaria.",
      icon: "fas fa-calculator"
    },
    {
      title: "Declaraciones Fiscales",
      description: "Presentamos tus declaraciones de manera precisa y puntual.",
      icon: "fas fa-file-invoice"
    },
    {
      title: "Defensa Fiscal y Apelaciones",
      description: "Protege tus derechos con defensa ante auditorías y apelaciones de multas.",
      icon: "fas fa-balance-scale"
    },
    {
      title: "Cumplimiento de Obligaciones Fiscales",
      description: "Evita sanciones cumpliendo con todas las normativas fiscales.",
      icon: "fas fa-clipboard-check"
    },
    {
      title: "Gestión de Nómina y Seguridad Social",
      description: "Administra eficientemente los pagos y beneficios de tu personal.",
      icon: "fas fa-users"
    }
  ];

  return (
    <div className="services-screen">
      {/* Sección de Servicios */}
      <section className="services-section">
        <h2 className="services-title">Nuestros Servicios</h2>
        <div className="services-container">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <i className={`${service.icon} service-icon`}></i>
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Sección de Planes */}
      <section className="plans-section">
        <h2 className="plans-title">Elige el Plan que se Ajuste a Ti</h2>
        <PlansSection />
      </section>
    </div>
  );
};

export default ServicesScreen;
