import React, { useEffect, useState } from "react";
import PlansSection from "../components/PlanSection";
import TrustedCompanies from "../components/TrustedCompanies";

const MainScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [contactoWhatsapp, setContactoWhatsapp] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Datos enviados:', { nombre, apellido, telefono, correo });
    };

    return (
        <div>
            {/* HERO SECTION */}
            <section className="hero-section">
                <div className="hero-content">
                    <div className="hero-text">
                        <h1>
                            ¿Te corresponde <span className="highlight">devolución de impuestos</span>?
                        </h1>
                        <h2>
                            Te ayudamos a realizar tu <span className="trust">Declaración Anual</span> de manera rápida y efectiva.
                            Aprovecha esta oportunidad y recibe lo que te corresponde.
                        </h2>
                        <a className="cta-button" href="https://wa.me/522381888842?text=Quiero%20hacer%20mi%20declaraci%C3%B3n%20anual">
                            Realizar mi Declaración Anual
                        </a>
                    </div>
                    <div className="hero-image">
                        <img src={process.env.PUBLIC_URL + '/media/declara.png'} alt="Asesoría en Declaración Anual" />
                    </div>
                </div>
            </section>

            {/* PLANES PARA PERSONAS FÍSICAS */}
            <section style={{ background: "white", padding: "16px", textAlign: "center" }}>
                <h2>Planes para Personas Físicas</h2>
                <PlansSection />  
            </section>
            
            {/* EMPRESAS QUE HAN CONFIADO EN NOSOTROS */}
            <section style={{  padding: "32px", textAlign: "center" }}>
                <TrustedCompanies />
            </section>
            <section className="education-section">
      <div className="education-content">
        <h2 className="education-title">Educación en Materia Contable y Fiscal</h2>
        <p className="education-text">
          Mantente informado con los últimos cambios fiscales y aprende a gestionar tu contabilidad de manera efectiva.
          Nuestro blog te ofrece artículos, guías y recomendaciones para optimizar tu situación fiscal.
        </p>
        <a href="/blog" className="education-btn">Visitar el Blog</a>
      </div>
    </section>
        </div>
    )
}

export default MainScreen;